import { inject, Injectable } from '@angular/core'
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { CookieService } from 'src/app/shared/services/helper/cookie.service'
import { CorrelationIdService } from 'src/app/shared/services/helper/correlation-id.service'
import { CacheService } from 'src/app/shared/services/helper/cache.service'

@Injectable()
export class FeHeaderInterceptor implements HttpInterceptor {
    cookieservice = inject(CookieService)
    correlationIdService = inject(CorrelationIdService)
    cacheService = inject(CacheService)

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const correlation = this.correlationIdService.getCorrelationId();
        const ssotoken = this.cookieservice.getSSOToken();
        const auth = this.cookieservice.getOktaJWT();
        const claimId = this.cacheService.getLocal('claimId')
        const participantId = this.cacheService.getLocal('participantId')
        const fiid = `${claimId}-${participantId}`
        const modifiedReq = request.clone({
            headers: request.headers
                .set('X_SF_SSO_TOKEN', ssotoken)
                .set('Authorization', auth)
                .set('correlation-id', correlation)
                .set('X-Form-Instance-Id', fiid)
        });
        return next.handle(modifiedReq)
    }
}

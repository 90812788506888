import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { ExternalModule } from './external/external.module';


//EVENT BUS
import { NgEventBus } from 'ng-event-bus';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { IdInterceptor } from './observablility/interceptors/id.interceptor';
import { ObservablilityModule } from './observablility/observablility.module';
import { LOGGER, Logger, consoleLogger } from './observablility/logger/token';
import { INGXLoggerConfig, LoggerModule, NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { FeHeaderInterceptor } from './forms-engine/interceptors/fe-header.interceptor';


@NgModule({
  declarations: [
    AppComponent
  ],

  imports: [
    ObservablilityModule,
    BrowserModule,
    CoreModule,
    SharedModule,
    ExternalModule,
    AppRoutingModule,
    LoggerModule.forRoot({
      ...environment.logger as INGXLoggerConfig,
      serverCallsBatchSize: 25,
    }),
  ],
  providers: [
    {
      provide: LOGGER,
      useFactory: (injector: Injector) => {
        // If nglogger exists then use it, else use the console
        return environment.useConsoleLogger != true ? injector.get(NGXLogger) : consoleLogger as Logger
      },
      //? for Injector info
      //? https://www.youtube.com/watch?v=zDVKrqxf5Ss&list=PLX7eV3JL9sfmJ6AaZj9eDlAKrJrEul4Vz&index=4
      deps: [Injector]
    },
    NgEventBus,
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: IdInterceptor
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: FeHeaderInterceptor
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { AuthenticationService } from 'src/app/external/service/authentication.service';
import { Dol } from '../../models/dol.model';
import { UrlService } from '../helper/url.service';
import { ValidatorService } from '../helper/validator.service';

@Injectable({
  providedIn: 'root'
})
export class DolService {
  dol: Dol = new Dol();

  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private validatorService: ValidatorService,
    private authenticationService: AuthenticationService,
  ) { }

  DOL(external_claim_id: string, external_participant_id: string): Observable<Dol> {
    if (this.validatorService.checkEmpty(this.dol.dol)) {
      let dolurl = this.urlService.getServiceUrl('dateofloss');
      dolurl = dolurl + "?external_claim_id=" + external_claim_id + "&external_participant_id=" + external_participant_id + "";
      return this.http.get(dolurl).pipe(
        switchMap((response: any) => {
          this.dol.dol = response.dol
          return of(this.dol)
        }),
        catchError((err: any) => {
          console.log(err)
          this.authenticationService.handleRoute(err);
          throw Error(`Error: ` + err)
        })
      )
    } else {
      return of(this.dol);
    }
  }

  getDOL() {
    return this.dol;
  }
}

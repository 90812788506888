import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap, timeout } from 'rxjs';
import { LoadingService } from 'src/app/core/services/loading.service';
import { VehicleDetailsService } from 'src/app/forms-engine/services/app-services/vehicle-details.service';
import { RouteParamsService } from 'src/app/routing/service/route-params.service';
import { UrlService } from 'src/app/shared/services/helper/url.service';
import { ClaimNumber } from '../model/claim_number.model';
import { ClientId } from '../model/client_id.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {
  private claimNumber: ClaimNumber = { claimNumber: '' };
  private clientId: ClientId = { clientId: '' };
  private claimsHubURL: string = '';

  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private routeParamsService: RouteParamsService,
    private loadingService: LoadingService,
    private authenticationService: AuthenticationService,
    private vehicleDetailsService: VehicleDetailsService
  ) { }

  getClaimNumberObservable(): Observable<ClaimNumber> {
    if (this.claimNumber.claimNumber == '') {
      this.loadingService.showLoading();
      const external_claim_id = this.routeParamsService.getClaimId();
      let claimnumURL = this.urlService.getServiceUrl('claimNumber');
      claimnumURL = claimnumURL + "?external_claim_id=" + external_claim_id + "";
      return this.http.get(claimnumURL).pipe(
        switchMap((response: any) => {
          this.loadingService.hideLoading();
          this.claimNumber = response as ClaimNumber;
          return of(this.claimNumber);
        }),
        catchError((err) => {
          this.loadingService.hideLoading();
          if (err.status == 401) {
            this.authenticationService.redirectToLogin();
          }
          throw Error(`Error: ` + err)
        })
      );
    } else {
      return of(this.claimNumber)
    }
  }

  getClaimNumber(): string {
    return this.claimNumber.claimNumber
  }

  getClientIdObservable(): Observable<ClientId> {
    if (this.clientId.clientId == '') {
      this.loadingService.showLoading();
      let customerInfoURL = this.urlService.getServiceUrl('clientId');
      return this.http.get(customerInfoURL).pipe(
        timeout(3000),
        switchMap((response: any) => {
          this.loadingService.hideLoading();
          this.clientId = response as ClientId;
          return of(this.clientId);
        }),
        catchError((err) => {
          this.loadingService.hideLoading();
          throw Error(`Error: ` + err)
        })
      );
    } else {
      return of(this.clientId);
    }
  }

  getClientId(): string {
    return this.clientId.clientId;
  }

  getClaimsHubURLObservable(): Observable<string> {
    if (this.claimsHubURL == '') {
      return this.getClientIdObservable().pipe(switchMap(data => {
        const claim_id = this.routeParamsService.getClaimId();
        const client_id = data.clientId;
        this.claimsHubURL = this.urlService.getServiceUrl('claimsHub');
        this.claimsHubURL = this.claimsHubURL + `claim/${claim_id}/client/${client_id}/role/NI`;
        return of(this.claimsHubURL);
      })
      );
    } else {
      return of(this.claimsHubURL);
    }
  }

  cacheData(): void {
    this.routeParamsService.removeParameters();
    this.vehicleDetailsService.removeVehicleDetails();
  }
}
